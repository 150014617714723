import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  connect() {
    console.log("legendary_regiments#connect");
    new TomSelect(this.element, {
      create: true,
      createOnBlur: true,
      sortField: {
        field: "text",
        direction: "asc"
      },
      plugins: ['remove_button', 'dropdown_input']
    })
  };
}
