import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  connect() {
    console.log("magic_items#connect");
    new TomSelect(this.element, {
      valueField: 'name_en',
      labelField: 'name_en',
      searchField: ['name_en'],
      preload: true,
      load: function(query, callback) {
        var url = 'https://raw.githubusercontent.com/nthiebes/old-world-builder/refs/heads/main/public/games/the-old-world/magic-items.json';
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json);
          }).catch(()=>{
            callback();
          });
      },    
      sortField: {
        field: "text",
        direction: "asc"
      },
      plugins: ['remove_button', 'dropdown_input']
    })
  };
}
